import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer_v1 extends Component {

	
	constructor(props) {
		super(props);
		this.state = {
		  Newscategories: [],
		};
	  }
	
	  fetchCategories() {
		const apiUrlNewsCat = 'https://sportysphere.com/api/blog.php?category=news_categories';
	
		  fetch(apiUrlNewsCat)
		  .then(response => response.json())
		  .then(data => {
			// Assuming the data is an array of categories
			if (Array.isArray(data)) {
			  this.setState({ Newscategories: data });
			} else {
			  console.error('Unexpected data format:', data);
			}
		  })
		  .catch(error => console.error('Error fetching categories:', error));
	  }

	  componentDidMount() {
		const $ = window.$;

		let publicUrl = process.env.PUBLIC_URL + '/'
		const minscript = document.createElement("script");
		minscript.async = true;
		minscript.src = publicUrl + "assets/js/main.js";

		document.body.appendChild(minscript);

		var bodyOvrelay = $('#body-overlay');
		var searchPopup = $('#td-search-popup');

		$(document).on('click', '#body-overlay', function (e) {
			e.preventDefault();
			bodyOvrelay.removeClass('active');
			searchPopup.removeClass('active');
		});
		$(document).on('click', '.search', function (e) {
			e.preventDefault();
			searchPopup.addClass('active');
			bodyOvrelay.addClass('active');
		});
		// Fetch categories when component mounts
		this.fetchCategories();
	}

	render() {
		const { Newscategories } = this.state;

		return (
			<div className="footer-area bg-black pd-top-95">
				<div className="container">
					<div className="row">
						<div className="col-lg-3 col-sm-6">
							<div className="widget">
								<h5 className="widget-title">ABOUT US</h5>
								<div className="widget_about">
									<p>Stay ahead of the game with the latest sports news, insights, and expert analysis. Your go-to source for everything sports!</p>
									<ul className="social-area social-area-2 mt-4">
										<li><a className="facebook-icon" href="https://www.facebook.com/profile.php?id=61566769855926"><i className="fa fa-facebook" /></a></li>
										<li><a className="instagram-icon" href="https://www.instagram.com/solverwp/"><i className="fa fa-instagram" /></a></li>
									</ul>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-sm-6">
							<div className="widget widget_tag_cloud">
								<h5 className="widget-title">QUICK LINKS</h5>
								<div className="tagcloud go-top">
								{Newscategories.map(category => (
										<Link key={category.id} to={`/cat-news/${category.id}`}>{category.category}</Link>
									))}
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-sm-6">
							<div className="widget">
								<h5 className="widget-title">CONTACTS</h5>
								<ul className="contact_info_list">
									<li><i className="fa fa-envelope-o" /> Info@sportysphere.com</li>
									<li><i className="fa fa-envelope-o" /> content@sportysphere.com</li>
									<li><i className="fa fa-envelope-o" /> newsletters@sportysphere.com</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-3 col-sm-6 go-top">
							<div className="widget widget_recent_post">
								<h5 className="widget-title">POPULAR LINKS</h5>
								<ul className="contact_info_list">
								<li><Link to="/">Home</Link></li>
								<li><Link to="/news">News</Link></li>
								</ul>
							</div>
						</div>
					</div>
					<div className="footer-bottom text-center">
						<ul className="widget_nav_menu go-top">
						{/* {Newscategories.map(category => (
							<li key={category.id}>
								<Link to={`/cat-news/${category.id}`}>{category.category}</Link>
							</li>
						))} */}
						</ul>
						<p>Copyright ©2024 Sporty Sphere</p>
					</div>
				</div>
			</div>

		)
	}
}


export default Footer_v1